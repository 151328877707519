import {ActivityStatusKind, StudentActivityDto} from "@core/app-services/course-services";


export class LearningActivitySection {
    title = '';
    activities: StudentActivityDto[] = [];
    status: ActivityStatusKind;
    get hasActivities() {
        
        return this.activities && this.activities.length > 0;
    }
    constructor(title: string, status: ActivityStatusKind, activities: StudentActivityDto[]) {
        this.title = title;
        this.status = status;
        this.activities = activities;
    }
}

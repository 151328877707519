import {Injectable} from '@angular/core';
import {GetStudentProfileResponse, ProfileAppService, StudentProfileDto} from '@core/app-services/profile-services';
import {Observable, BehaviorSubject} from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    profile: StudentProfileDto = new StudentProfileDto();
    defaultAvatarUrl: string = 'assets/images/profile/avatar.png'
    avatarImageUrl: string = 'assets/images/profile/profile.png';
    onAvatarChanged: BehaviorSubject<string> = new BehaviorSubject<string>("");
    onProfileChanged: BehaviorSubject<StudentProfileDto> = new BehaviorSubject<StudentProfileDto>(new StudentProfileDto());
    constructor(private profileAppService: ProfileAppService) {

    }

    async getStudentProfile(studentId: number): Promise<GetStudentProfileResponse> {
        
        const response = await this.profileAppService.getStudentProfile(studentId).pipe(
            retry(2)
        ).toPromise();
        this.profile = response.profile;
        let avatarUrl = this.defaultAvatarUrl;
        if(this.profile.avatarImageId > 0){
            avatarUrl = this.profile.avatarImageUrl;
        }
        this.onProfileChanged.next(this.profile);
        this.changeAvatarUrl(avatarUrl);
        return response;
        
    }


    changeAvatarUrl(url: string){
        this.avatarImageUrl = url;
        this.onAvatarChanged.next(url);
    }
}

import { Injectable } from '@angular/core';
import { AchievementInfo, AchievementsAppService, ActivityRewardsDto } from '../app-services/course-services';

@Injectable({
  providedIn: 'root'
})
export class AchievementsService {

  constructor(private _achievementsApi: AchievementsAppService) { }


  async getAchievementForActivity(activityId: string, userId: number): Promise<ActivityRewardsDto> {
    
    let result = await this._achievementsApi.getActivityRewards(activityId, userId).toPromise();
    return result;

  }

  async getAchievements(userId: number): Promise<AchievementInfo[]> {
    
    let result = await this._achievementsApi.getAchievements(userId).toPromise();
    return result;

  }


}

import {Injectable} from '@angular/core';
import {StudentActivityDto, ActivityAppService} from '@core/app-services/course-services';
import {Router} from '@angular/router';
import { AppSessionService } from '@app/core/auth/app-session.service';

@Injectable({ providedIn: 'root' })
export class ActivityPlayerService {
    private activityMapping = new Map<string, string>([
        ['quiz', '/quiz'],
        ['presentation', '/lessons/presentation'],
        ['recording', '/lessons/video'],
    ]);

    currentActivity: StudentActivityDto = new StudentActivityDto();

    constructor(private router: Router, private _activityApi: ActivityAppService, private _appSession: AppSessionService) {
    }

    async playActivity(activity: StudentActivityDto): Promise<void> {
        if (!this.activityMapping.has(activity.activityType.toLowerCase())) {
            console.warn('Unmapped activity type', activity.activityType);
            return;
        }

        const activityRoute = this.activityMapping.get(activity.activityType.toLowerCase());
        this.currentActivity = activity;
        await this.router.navigate([activityRoute, activity.activityId]);
    }


    async getStudentActivity(activityId:string): Promise<StudentActivityDto> {
        
        if(this.currentActivity && this.currentActivity.id == activityId){
            return this.currentActivity;
        }

        this.currentActivity = await this._activityApi.getActivity(this._appSession.userId, activityId).toPromise();

        return this.currentActivity;

    }
}
